<template>
  <div class="mt-4"  v-if="requestDetails !== null" >
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center relative">
        <h1 class="heading-2 text-center">{{requestDetails.itemName}}</h1>
        <div class="flex items-center gap-2 absolute right-1">
            <div class=" text-error heading-4 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
              <i class="fa-solid fa-trash"></i>
            </div>
            <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="updateExp()">
              <i class="fa-solid fa-pen-to-square"></i>
            </div>
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
      <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-7/12">
        <div class="py-2 card rounded-xl bg-white p-4 my-4 relative">
          <!-- <div class="text-success heading-6 absolute right-2 top-2 border border-success p-1 w-16 text-center mt-2 rounded-md" v-if="requestDetails.isMarkPaid">Paid</div>
          <div class="pt-2 " v-if="!requestDetails.isMarkPaid">
            <span class="text-error heading-6 border border-error p-1 px-4 text-center rounded-md">Unpaid</span>
          </div> -->
          <div class="py-2 flex items-center justify-between">
            <div class="">
              <div class="text-gray3 font-bold">Added By</div>
              <div class="text-text1 heading-5">{{requestDetails.firstName}} {{requestDetails.lastName}}</div>
            </div>
            <div class="mb-4" v-if="!requestDetails.isMarkPaid && isAdmin">
              <Button @buttonAction="markPaidPopup=true" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Mark as Paid'"/>
            </div>
            <div class="mb-4" v-if="requestDetails.isMarkPaid && isAdmin">
              <Button @buttonAction="markPaid(false)" :btnSize="'medium'" :textColor="'white'" :btnColor="'gray4'" :btnText="'Mark as Unpaid'"/>
            </div>
          </div>
          <div class="py-2 flex items-center justify-between">
            <div class="">
              <div class="text-gray3 font-bold">Reimburse to</div>
              <div class="text-text1 heading-5" v-if="requestDetails.reimbersUserName !== ''">{{requestDetails.reimbersUserName}}</div>
              <p class="text-text1 heading-5" v-else>Not Reimbursable</p>
            </div>
            <div class="" >
              <div class="text-gray3 font-bold">Expense Date </div>
              <div class="text-text1 heading-5">{{requestDetails.expenseDate | formatForDatePicker}}</div>
            </div>
          </div>
          <div class="py-2" v-if="requestDetails.accountingCodeName !== ''">
            <div class="text-gray3 font-bold">Accounting Code</div>
            <div class="text-text1 heading-5">{{requestDetails.accountingCodeName}}</div>
          </div>
          <div class="py-2 flex items-center justify-between">
            <div class="">
              <div class="text-gray3 font-bold">Total Amount</div>
              <div class="text-text1 heading-5">{{requestDetails.totalAmount | amountFormaterWithToFix}}
              <span class="heading-6 font-semibold" :class="requestDetails.isMarkPaid ? 'text-success' : 'text-error'">({{requestDetails.isMarkPaid ? 'Paid' : 'Unpaid'}})</span>
              </div>
            </div>
          </div>
          <div class="py-2" v-if="requestDetails.jobId > 0">
            <div class="text-gray3 font-bold">Job</div>
            <div class="text-text1 heading-5">#{{requestDetails.jobNumber}} {{requestDetails.jobTitle}}</div>
            <div class="text-gray4 heading-6">{{requestDetails.companyName}}</div>
          </div>
        </div>
        <div class="card bg-white rounded-xl p-4 my-4 " v-if="requestDetails.detail !== ''">
          <div class="text-gray3 font-bold">Expense Detail </div>
          <div class="text-text1 heading-5 whitespace-pre-line mt-1">{{requestDetails.detail}}</div>
        </div>
        <div class="card bg-white rounded-xl p-4 my-4 " v-if="attachmentList.length > 0">
          <div class="text-gray3 font-bold">Receipt</div>
               <div class="flex gap-4" style="flex-wrap: wrap;">
                <div class=" card flex rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer group" 
                style="width: 120px; height: 80px" 
                v-for="(attach, a) in attachmentList " :key="a" @click="showFullImage(attach)">
                  <div v-if="attach.docType === 'image'" class="text-center self-center place-self-center ">
                    <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                    <div class="invisible group-hover:visible absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
                  <div v-if="attach.docType === 'gif'"  class="flex justify-center items-center w-full relative">
                    <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-image"></i></div>
                    <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
                  <div v-if="attach.docType === 'svg'"  class="flex justify-center items-center w-full relative">
                    <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-code"></i></div>
                    <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
                  <div v-if="attach.docType === 'video'"  class="flex justify-center items-center w-full relative">
                    <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                    <img class="flex items-center  text-4xl absolute text-white " height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                    <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
                  <div v-if="attach.docType === 'text'"  class="flex justify-center items-center w-full relative">
                    <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                    <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
                  <div v-if="attach.docType === 'document'"  class="flex justify-center items-center w-full relative">
                    <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-excel"></i></div>
                    <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
                  <div v-if="attach.docType === 'pdf'"  class="flex justify-center items-center w-full relative">
                    <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                    <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.fileName }}</div>
                  </div>
               </div>
            </div>
        </div>
        <div class="card bg-white rounded-xl p-4 my-4" v-if="requestDetails.isMarkPaid">
          <div class="pb-2">
            <div class="text-gray3 font-bold">Paid Date</div>
            <div class="text-text1 heading-5">{{requestDetails.paidDate | formatForDatePicker}}</div>
          </div>
          <div class="pt-2">
            <div class="text-gray3 font-bold">Paid Note</div>
            <div class="text-text1 heading-5 whitespace-pre-line" v-if="requestDetails.paidNote !== ''">{{requestDetails.paidNote}}</div>
            <div class="text-text1 heading-5" v-else>--</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isExpmark" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="isExpmark = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteAddress"/>
        </div>
      </div>
    </div>
    <div v-if="removeConfirm" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="removeConfirm = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteAddress"/>
        </div>
      </div>
    </div>
    <div v-if="markPaidPopup" class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 600px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div class="">
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              Mark Paid
            </div>
          </div>
          <div class="p-4">
            <div>
              <TextAreaInput
              :inputId="'invicenoteid12'"
              :inputext="paidNote"
              :placholderText="`Write Note here...`"
              :lableText="'Paid Note'"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="1000"
              :showcharLimit="true"
              :cols="50"
              :rows="3"
              @keyPressAction="paidNoteErr = ''"
              :fieldError="paidNoteErr !== ''"
              :setReadOnly="false"
              @inputChangeAction="(data) => paidNote = data" 
              />
              <p v-if="paidNoteErr !== ''" class="text-error heading-7">{{paidNoteErr}}</p>
            </div>
            <div class="my-4 sm:mb-0">
              <TextField :fieldError="paidDateErr !== ''" @keyPressAction="paidDateErr = ''" :isPointer="true" :placholderText="`Date`" :setReadOnly="true" :autoFocus="false" :inputext="paidDate" :showDate="true" :lableText="'Date'" :inputId="'Invoicedkid'" @onClickInputAction="invoiceDatePickShow = true" />
              <div v-if="invoiceDatePickShow" class="popup_overlay">
                <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                  <DateTimePickerCustom
                    v-model="paidDate"
                    v-if="invoiceDatePickShow"
                    :showDate="true"
                    :title="'Date'"
                    :showOnlyDate="true"
                    :showStartDate="true"
                    @input="paidDateErr = ''"
                    :projectSection="true"
                    @closeDateTimepickerDialog="invoiceDatePickShow = false">
                  </DateTimePickerCustom>
                </div>
              </div>
              <p v-if="paidDateErr !== ''" class="text-error heading-7">{{paidDateErr}}</p>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="markPaidPopup= false"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="markPaid(true)"/>
            </div>
          </div>
        </div>
    </div>
  </div>
    <FullImage v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" />
  </div>
</template>
<script>
import TextField from '@/View/components/textfield.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import moment from 'moment'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Button from '@/View/components/globalButton.vue'
import FullImage from '@/View/expense/components/fullScreenExpense.vue'
import MyJobApp from "@/api/MyJobApp.js";
export default {
  name: "lead",
  components: {
    TextField,
    DateTimePickerCustom,
    FullImage,
    TextAreaInput,
    Button
  },
  data() {
    return {
      paidNoteErr: '',
      paidDateErr: '',
      paidDate: '',
      invoiceDatePickShow: false,
      paidNote: '',
      markPaidPopup: false,
      isExpmark: false,
      removeConfirm: false,
      pathPrefix: '',
      attachmentList: [],
      requestDetails: null,
      fileVaultId: 0,
      fileType: '',
      fileName: '',
      shoFullImage: false,
      isAdmin: false,
    };
  },
  created() {
    this.isAdmin = JSON.parse(localStorage.getItem('jobUserInfo')).isAdmin
  },
  mounted() {
    this.paidDate = moment(new Date()).format('YYYY-MM-DD')
    document.body.style = 'overflow: visible;'
     this.getsJobDetail()
     this.$root.$on('fullImageHandler', () => {
      document.body.style = 'overflow: visible;'
      this.shoFullImage = false
    })
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
    this.$root.$off("fullImageHandler");
  },
  methods: {
    deleteAddress () {
      document.body.style = 'overflow: visible;'
      if (this.removeConfirm) {
        this.deleteReq()
      }
      this.removeConfirm = false
    },
    updateExp () {
      this.$router.push({name: 'EditExpense', params: {expId: this.$route.params.expId}})
    },
    markPaid (data) {
      let isValidA = true
      if (this.paidDate === '') {
        isValidA = false
        this.paidDateErr = 'Date is required'
      }
      if (!data) {
        isValidA = true
      }
      if (isValidA) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.ExpenseMarkPaid(
          data,
          parseInt(this.$route.params.expId),
          this.paidDate,
          this.paidNote,
          (response) => {
            this.markPaidPopup = false
            this.getsJobDetail()
            this.requestDetails.paidDate = moment(new Date()).format('YYYY-MM-DD')
            this.requestDetails.paidNote = ''
            this.paidNoteErr = ''
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    deleteReq () {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.getExpenseDelete(
        parseInt(this.$route.params.expId),
        (response) => {
          this.$router.push({ name: 'Expense'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    showFullImage (data) {
      this.fileVaultId = data.fileVaultId
      this.fileType = data.docType
      this.fileName = data.fileName
      this.shoFullImage = true
    },
    getsJobDetail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.getExpenseDetail(
        parseInt(this.$route.params.expId),
        (response) => {
          this.requestDetails = response.Data;
          this.pathPrefix = this.requestDetails.pathPrefix
          let Temp = []
          this.attachmentList = []
          if (response.Data.attachments !== null) {
            Temp = JSON.parse(response.Data.attachments).attachmentList
            if (Temp !== null) {
              for (let index = 0; index < Temp.length; index++) {
                let attach = Temp[index]
                if (attach.fileType === 'image/png' || attach.fileType === 'image/jpg' || attach.fileType === 'image/jpeg') {
                  attach.docType = 'image'
                } else if (attach.fileType === 'video/mov' || attach.fileType === 'video/mvi' || attach.fileType === 'video/mp4' || attach.fileType === 'video/mkv' || attach.fileType === 'video/mpeg' || attach.fileType === 'video/avi' || attach.fileType === 'video/mpeg4') {
                  attach.docType = 'video'
                } else if (attach.fileType === 'application/xls' || attach.fileType === 'application/xlsx' || attach.fileType === 'application/ods' || attach.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                  attach.docType = 'document'
                } else if (attach.fileType === 'application/doc' || attach.fileType === 'application/docx' || attach.fileType === 'application/odt' || attach.fileType === 'application/pdf' || attach.fileType === 'application/octet-stream') {
                  attach.docType = 'pdf'
                } else if (attach.fileType === 'image/svg' || attach.fileType === 'image/svg+xml') {
                  attach.docType = 'svg'
                } else if (attach.fileType === 'text/csv') {
                  attach.docType = 'text'
                } else if (attach.fileType === 'image/gif') {
                  attach.docType = 'gif'
                }
                this.attachmentList.push(attach)
              }
            }
          }
          document.body.style = 'overflow: visible;'
          console.log('Temp', Temp)
          console.log('this.requestDetails', this.requestDetails)
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
};
</script>
<style scoped>
</style>